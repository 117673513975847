<template>
  <div class="container performance" v-loading="loading">
    <div class="top">
      <div class="name">{{ details.name }}</div>

      <!-- <div class="tag_style cursorP" @click="toCompanyDetail">查看企业详情</div> -->
    </div>

    <div class="table">
      <div class="table_cell">
        <div class="label">公司名称</div>
        <div class="value">
          {{ details.companyName | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">项目名称</div>
        <div class="value">
          {{ details.name | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">项目代码</div>
        <div class="value">
          {{ details.projectCode | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">项目类型</div>
        <div class="value">
          {{ details.type | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">所在省份</div>
        <div class="value">
          {{ details.province | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">建设状态</div>
        <div class="value">
          {{ details.state | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">技术等级</div>
        <div class="value">
          {{ details.technology | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">合同价（万元）</div>
        <div class="value">
          {{ details.amount | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">结算价（万元）</div>
        <div class="value">
          {{ details.settlementPrice }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">合同段名称</div>
        <div class="value">
          {{ details.contractSectionName | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">合同段开始桩号</div>
        <div class="value">
          {{ details.contractStartNumber | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">合同段结束桩号</div>
        <div class="value">
          {{ details.contractEndNumber | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">创建时间</div>
        <div class="value">
          {{ details.createTime | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">开工时间</div>
        <div class="value">
          {{ details.startTime | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">交工时间</div>
        <div class="value">
          {{ details.endTime1 | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">竣工时间</div>
        <div class="value">
          {{ details.endTime2 | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">更新时间</div>
        <div class="value">
          {{ details.updateTime | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">主要工程量</div>
        <div class="value">
          {{ details.quantityOfWork | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">质量评定情况</div>
        <div class="value">
          {{ details.quality | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">信息来源</div>
        <div class="value">
          {{ details.informationSources | noVoid }}
        </div>
      </div>
    </div>

    <div class="public_title mt20">人员履约信息</div>

    <el-table
      v-loading="loading"
      :data="details.personList"
      border
      style="width: 100%"
      ref="personnel"
    >
      <el-table-column label="序号" type="index" width="50"> </el-table-column>

      <el-table-column
        sortable
        prop="name"
        min-width="100"
        :show-overflow-tooltip="true"
        label="姓名"
      ></el-table-column>

      <el-table-column
        sortable
        prop="post"
        min-width="100"
        :show-overflow-tooltip="true"
        label="担任岗位或专业负责人"
      ></el-table-column>

      <el-table-column
        sortable
        prop="announcedUnits"
        min-width="100"
        :show-overflow-tooltip="true"
        label="任职日期"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.startTime">{{ scope.row.startTime }}</span>
          <span v-if="scope.row.startTime && scope.row.endTime">~</span>
          <span v-if="scope.row.endTime">{{ scope.row.endTime }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
/* 水利专查业绩详情 */
import { getHighroadDetails } from "@/api/highroad.js";

export default {
  data() {
    return {
      id: "", // id
      sourceCode: "", // 来源编码
      details: {}, // 详情
      loading: false, // 加载动画
    };
  },

  methods: {
    /* 获取数据 */
    // 获取详情
    async getData() {
      this.loading = true;
      const params = {
        id: this.id,
      };
      const { code, data } = await getHighroadDetails(params);
      if (code === 0) {
        data.personList = data.personList || [];
        this.details = data;
        this.loading = false;
      }
    },

    /* 操作 */
    // 跳转到网页
    linkToUrl(url) {
      if (url) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
      } else {
        this.$message.warning("暂无跳转地址");
      }
    },
    // 跳转到企业详情页面
    toCompanyDetail() {
      let id = this.details.companyId;
      if (id) {
        this.$router.push({
          name: "companyDetails",
          params: { name: "企业业绩" },
          query: {
            id,
          },
        });
      } else {
        this.$message.warning("暂无企业信息！");
      }
    },

    /* 初始化 */
    __init__() {
      this.id = this.$route.params.id;
      this.getData();
    },
  },

  created() {
    this.__init__(); // 初始化
  },

  activated() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.performance {
  padding: 20px;
  background-color: #fff;

  .top {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      font-size: 20px;
      font-weight: bold;
      color: #333;
    }
  }

  .table {
    .table_cell {
      display: flex;
      align-items: stretch;
      border: 1px solid #ecf1ff;
      border-bottom: none;
      border-right: none;
      font-size: 14px;

      &:last-child {
        border-bottom: 1px solid #ecf1ff;
      }

      .label {
        padding: 15px;
        min-width: 200px;
        text-align: center;
        color: #666;
        display: flex;
        align-items: center;
        background-color: #f5faff;
      }

      .value {
        display: flex;
        align-items: center;
        padding: 15px;
        flex: 1;
        color: #666;
        border-right: 1px solid #ecf1ff;
        border-left: 1px solid #ecf1ff;
        line-height: 1.5;
      }
    }
  }
}
</style>
